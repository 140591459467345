import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Agenda from '../components/Schedule/Agenda';
import Preloader from '../components/UI/Preloader';

const Schedule = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getBookedAppointments = async () => {
            try {
                const { data } = await axios.get(`https://dxaz3vgpvh.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/booking`);
      
                setBookings(data.body);
                setIsLoading(false);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getBookedAppointments();
    }, [])

    return (
        <div className="BAJA-content-wrapper">
            <div className="h1 mb-4">
                Upcoming Appointments
            </div>
            <Preloader
                loading={isLoading}
            >
                <Agenda 
                    bookings={bookings}
                />
            </Preloader>
        </div>
    )
}

export default Schedule;