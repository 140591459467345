import React from 'react';
import styles from './Column.module.css';
import Cell from './Cell';

const Column = (props) => {
    const cells = props.hours.map((cell, index) => {
        return (
            <Cell 
                key={index}
                name={cell.name}
                hour={cell.hour}
                year={props.day.year}
                month={props.day.month}
                day={props.day.day}
                weekDay={props.day.weekDay}
                availableAppointments={props.availableAppointments}
                bookedAppointments={props.bookedAppointments}
                setBookingHandler={props.setBookingHandler}
                booking={props.booking}
            />
        )
    });

    return (
        <div className={styles.column}>
            {cells}
        </div>
    )
}

export default Column;