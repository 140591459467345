import React from 'react';
import SectionTitle from '../Typography/SectionTitle';
import Input from '../UI/Input';

const ContactInfo = ({ bookingForm, inputChangedHandler }) => {
    return (
        <div className="mb-4">
            <SectionTitle>
                Contact Information
            </SectionTitle>
            <div className="row">
                <div className="col-sm">
                    <Input
                        type="text"
                        id="firstName"
                        label="First name"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.firstName}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
                <div className="col-sm">
                    <Input
                        type="text"
                        id="lastName"
                        label="Last name"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.lastName}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Input
                        type="text"
                        id="emailAddress"
                        label="Email"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.emailAddress}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
                <div className="col-sm">
                    <Input
                        type="text"
                        id="phone"
                        label="Phone"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.phone}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;