import React from 'react';
import styles from './Cell.module.css';
import dayjs from 'dayjs';

const Cell = (props) => {
    const CUTOFF_WINDOW_HOURS = 3;

    const hour = props.hour;
    const year = props.year;
    const month = props.month;
    const day = props.day;
    const weekDay = props.weekDay;
    const availableAppointments = props.availableAppointments;
    const bookedAppointments = props.bookedAppointments;
    const bookedAppointmentsLookup = [];
    const selectedBooking = props.booking;

    for (const booking of bookedAppointments) {
        const bookingDate = dayjs(booking.bookingDateTime).format('YYYYMMDDHHmm');

        bookedAppointmentsLookup.push(bookingDate);
    }

    const availableBookings = availableAppointments.map((booking, index) => {
        const bookingDate = dayjs(`${year}-${month}-${day}T${booking.hour}:${booking.minute}:00`);
        const compareDate = bookingDate.format('YYYYMMDDHHmm');
        const cutoffDate = dayjs().add(CUTOFF_WINDOW_HOURS, 'h');
        
        if (
            !bookedAppointmentsLookup.includes(compareDate) &&
            booking.weekDays.includes(weekDay) &&
            hour === booking.hour &&
            bookingDate.isAfter(cutoffDate)
        ) {
            let selected = '';

            if (bookingDate.isSame(selectedBooking)) {
                selected = styles.selected;
            }

            return (
                <button 
                    key={index}
                    type="button"
                    className={`${styles.block} ${selected}`} 
                    onClick={() => props.setBookingHandler(bookingDate)}
                >
                    {bookingDate.format('h:mm A')}
                </button>
            );
        } else {
            return null;
        }
    });

    return (
        <div className={styles.cell}>
            {availableBookings}
        </div>
    )
}

export default Cell;