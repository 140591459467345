import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ContactInfo from '../components/BookAppointment/ContactInfo';
import VehicleInfo from '../components/BookAppointment/VehicleInfo';
import ClaimInfo from '../components/BookAppointment/ClaimInfo';
import Calendar from '../components/BookAppointment/Calendar';
import Button from '../components/UI/Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

const BookAppointment = () => {
    const history = useHistory();
    const [bookedAppointments, setBookedAppointments] = useState([]);
    const [targetWeek, setTargetWeek] = useState(0);
    const [booking, setBooking] = useState(null);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bookingForm, setBookingForm] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
        year: '',
        make: '',
        model: '',
        insuranceCompany: '',
        claimNumber: '',
        damageDescription: '',
        bookingDateTime: ''
    });

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getBookedAppointments = async () => {
            try {
                const { data } = await axios.get(`https://dxaz3vgpvh.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/booking`);
      
                setBookedAppointments(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getBookedAppointments();
    }, []);

    const NUM_WEEKS = 4;
    const now = dayjs();
    const weeks = [];
    let week = [];
    
    const curWeekDay = now.day();
    const firstDay = dayjs(now.subtract(curWeekDay, 'd'));

    for (let i = 0; i <= 6; i++) {    
        let day = dayjs(firstDay.add(i, 'd'));
        week.push(day);
    }

    weeks.push(week);

    for (let i = 1; i <= NUM_WEEKS; i++) {
        week = [];

        const tempWeek = dayjs(now.add(i, 'w'));
        const firstDay = tempWeek.day(0);

        for (let i = 0; i <= 6; i++) {
            let day = dayjs(firstDay.add(i, 'd'));
            week.push(day);
        }

        weeks.push(week);
    }

    const availableAppointments = [
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 10,
            minute: '00'
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 10,
            minute: 15
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 10,
            minute: 30
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 10,
            minute: 45
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 14,
            minute: '00'
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 14,
            minute: 15
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 14,
            minute: 30
        },
        {
            weekDays: [
                1, 2, 3, 4
            ],
            hour: 14,
            minute: 45
        }
    ];

    const setWeekHandler = (index) => {
        setTargetWeek(index);
    }

    const setBookingHandler = (booking) => {
        setBooking(booking);
        dayjs.extend(utc);
        // const formattedDate = booking.format('YYYY-MM-DD HH:mm:ssZ');
        const formattedUTCDate = booking.utc().format('YYYY-MM-DD HH:mm:ss');        
        // console.log(`formattedDate: ${formattedDate}`);
        // console.log(`formattedUTCDate: ${formattedUTCDate}`);

        setBookingForm(bookingForm => ({...bookingForm, bookingDateTime: formattedUTCDate}));

        if (
            booking !== '' &&
            bookingForm.FirstName !== '' &&
            bookingForm.lastName !== '' &&
            bookingForm.emailAddress !== '' &&
            bookingForm.phone !== ''
        ) {
            setAllowSubmit(true);
        } else {
            setAllowSubmit(false);
        }
    }

    const inputChangedHandler = (event, inputId) => {
        let newValue = event.target.value;
        setBookingForm(bookingForm => ({...bookingForm, [inputId]: newValue}));

        let isValid = true;
        
        const formElems = [
            'firstName',
            'lastName',
            'emailAddress',
            'phone'
        ];

        if (newValue === '' && isValid) {
            isValid = false;
        }

        for (let formElemKey in formElems) {
            if (formElemKey !== inputId) {
                if (bookingForm[formElemKey] === '' && isValid) {
                    isValid = false;
                }
            }
        }

        if (bookingForm.bookingDateTime === '' && isValid) {
            isValid = false;
        }

        if (isValid) {
            setAllowSubmit(true);
        } else {
            setAllowSubmit(false);
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const postData = {
            bookingDateTime: bookingForm.bookingDateTime,
            firstName: bookingForm.firstName,
            lastName: bookingForm.lastName,
            emailAddress: bookingForm.emailAddress,
            phone: bookingForm.phone,
            year: bookingForm.year,
            make: bookingForm.make,
            model: bookingForm.model,
            insuranceCompany: bookingForm.insuranceCompany,
            claimNumber: bookingForm.claimNumber,
            damageDescription: bookingForm.damageDescription
        };

        const postAppointment = async () => {
            const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

            try {
                const { data } = await axios.post(`https://dxaz3vgpvh.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/booking`, postData);

                // validate response
                if (data.statusCode === 201) {
                    const bookingId = data.body.bookingId;
                    // systemMessageCtx.setSystemMessage('Your quote was created successfully.', 'success');
                    history.push(`/confirm-appointment/${bookingId}`);
                }
            } catch(error) {
                console.log(`error: ${error}`);
                setIsLoading(false);

                // systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
            }
        }

        postAppointment();
    }

    return (
        <React.Fragment>
            <div className="h1 mb-4">
                Book an Appointment
            </div>
            <div className="BAJA-copy mb-5 BAJA-blue-bg">
                Book an appointment for a FREE estimate at Baja Autobody Ltd. Please provide your contact information and choose an available appointment time using the form below.
            </div>
            <form onSubmit={submitHandler} autoComplete="off" noValidate>
                <div className="row mb-4 gx-5">
                    <div className="col-lg-5">
                        <ContactInfo 
                            bookingForm={bookingForm}
                            inputChangedHandler={inputChangedHandler}
                        />
                        <VehicleInfo
                            bookingForm={bookingForm}
                            inputChangedHandler={inputChangedHandler}
                        />
                        <ClaimInfo
                            bookingForm={bookingForm}
                            inputChangedHandler={inputChangedHandler}
                        />
                    </div>
                    <div className="col-lg-7">
                        <Calendar 
                            targetWeek={targetWeek}
                            booking={booking}
                            weeks={weeks}
                            availableAppointments={availableAppointments}
                            bookedAppointments={bookedAppointments}
                            setWeekHandler={setWeekHandler}
                            setBookingHandler={setBookingHandler}
                        />
                    </div>
                </div>
                <div className="mb-6">
                    <Button
                        classes="btn btn-primary BAJA-button-width"
                        type="submit"
                        loading={isLoading}
                        valid={allowSubmit}
                    >
                        Book Appointment
                    </Button>
                </div>
            </form>
            <div className="BAJA-copy mb-6 BAJA-blue-bg">
                Having trouble booking your appointment? Please call us at <a href="tel:6044614343">604-461-4343</a>.
            </div>
        </React.Fragment>
    )
}

export default BookAppointment;