import React from 'react';
import styles from './Heading.module.css';
import dayjs from 'dayjs';

const Heading = (props) => {
    const year = props.date.year;
    const month = props.date.month;
    const day = props.date.day;
    const now = dayjs();
    const date = dayjs(`${year}-${month}-${day}`); 

    let style = '';

    if (date.isSame(now, 'day')) {
        style = styles.current;
    } else if (date.isBefore(now, 'day')) {
        style = styles.past;
    }

    return (
        <div className={`${styles.heading} user-select-none ${style}`}>
            <div className={styles['day-name']}>
                {date.format('ddd')}
            </div>
            <div className={styles['day-number']}>
                {date.format('D')}
            </div>
        </div>
    )
}

export default Heading;