import React from 'react';
import Jumbotron from '../components/Home/Jumbotron';
import Guarantee from '../components/Home/Guarantee';
import Location from '../components/Home/Location';
import Features from '../components/Home/Features';

const Home = () => {
    return (
        <React.Fragment>
            <Jumbotron />
            <Features />
            <Guarantee />
            <Location />
        </React.Fragment>
    )
}

export default Home;