import React from 'react';
import SectionTitle from '../Typography/SectionTitle';
import Input from '../UI/Input';

const VehicleInfo = ({ bookingForm, inputChangedHandler }) => {
    return (
        <div className="mb-4">
            <SectionTitle>
                Vehicle Information (Optional)
            </SectionTitle>
            <div className="row">
                <div className="col-sm">
                    <Input
                        type="text"
                        id="year"
                        label="Year"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.year}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
                <div className="col-sm">
                    <Input
                        type="text"
                        id="make"
                        label="Make"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.make}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
                <div className="col-sm">
                    <Input
                        type="text"
                        id="model"
                        label="Model"
                        // helpText={`Please enter your first name.`}
                        // helpTextId="customerName-help"
                        value={bookingForm.model}
                        changed={(event) => inputChangedHandler(event, event.target.id)}
                    />
                </div>
            </div>
        </div>
    )
}

export default VehicleInfo;