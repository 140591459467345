import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from './containers/Home';
import Header from './components/layout/Header';
import Content from './components/layout/Content';
import Footer from './components/layout/Footer';
import PageNotFound from './containers/PageNotFound';
import BookAppointment from './containers/BookAppointment';
import SignIn from './containers/SignIn';
import ConfirmAppointment from './containers/ConfirmAppointment';
import Schedule from './containers/Schedule';

function App() {
    const [userData, setUserData] = useState(() => {
        const userId = sessionStorage.getItem('userId');
       
        let isLoggedIn = false;

        if (userId !== null && userId !== '') {
            isLoggedIn = true;
        }

        return { loggedIn: isLoggedIn };
    });

    const login = () => {
        setUserData(userData => ({...userData, loggedIn: true}));
    }

    const logout = () => {
        setUserData(userData => ({...userData, loggedIn: false}));

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('expirationDate');
        sessionStorage.removeItem('userId');
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/signin">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        {!userData.loggedIn 
                            ?
                                <SignIn 
                                    login={login}
                                />
                            :
                                <Home />
                        }
                    </Content>
                    <Footer />
                </Route>
                <Route exact path="/">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        <Home />
                    </Content>
                    <Footer />
                </Route>
                <Route exact path="/book-appointment">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        <BookAppointment />
                    </Content>
                    <Footer />
                </Route>
                <Route exact path="/confirm-appointment/:bookingId">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        <ConfirmAppointment />
                    </Content>
                    <Footer />
                </Route>
                <Route exact path="/schedule">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        {!userData.loggedIn 
                            ?
                                <Home />
                            :
                                <Schedule />
                        }
                    </Content>
                    <Footer />
                </Route>
                <Route path="*">
                    <Header 
                        userData={userData}
                        logout={logout}
                    />
                    <Content>
                        <PageNotFound />
                    </Content>
                    <Footer />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;