import React from 'react';
import styles from './Features.module.css';
import image from '../../baja_home_2.png';

const Features = () => {
    return (
        <div className={`${styles.features}`}>
            <div className="h1 mb-4 text-center">
                Port Moody Auto Body Repair
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className={`${styles.image}`}>
                        <img src={image} alt="Baja Autobody Ltd. - Port Moody Auto Body Repair." />
                    </div>
                </div>
                <div className={`${styles.copy} col-sm d-flex align-items-center`}>
                    <div className={`${styles['feature-list']}`}>
                        <ul>
                            <li>Toyota Specialists</li>
                            <li>Private Insurance Claims</li>
                            <li>Repairing All Makes &amp; Models</li>
                            <li>FREE Estimates</li>
                            <li>Courtesy Cars Available</li>
                            <li>Highest Quality Work</li>
                            <li>Expert Painting</li>
                            <li>Lifetime Guarantee</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;