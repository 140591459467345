import React from 'react';
import styles from './Calendar.module.css';
import Heading from './Calendar/Heading';
import Column from './Calendar/Column';
import TimeColumn from './Calendar/TimeColumn';
import Button from '../UI/Button';

const Calendar = ({ targetWeek, booking, weeks, availableAppointments, bookedAppointments, setWeekHandler, setBookingHandler }) => {
    const week = weeks[targetWeek];
    const days = [];

    for (const day of week) {
        days.push({
            name: day.format('ddd'),
            year: day.format('YYYY'),
            month: day.format('MM'),
            day: day.format('DD'),
            weekDay: day.day()        
        });
    };
    
    const hours = [
        {
            name: '9 AM',
            hour: 9
        },
        {
            name: '10 AM',
            hour: 10
        },
        {
            name: '11 AM',
            hour: 11
        },
        {
            name: '12 PM',
            hour: 12
        },
        {
            name: '1 PM',
            hour: 13
        },
        {
            name: '2 PM',
            hour: 14
        },
        {
            name: '3 PM',
            hour: 15
        }
    ];

    const dayHeadings = days.map((date, index) => {
        return (
            <Heading
                key={index}
                date={date}
            />
        )
    });

    const dayColumns = days.map((day, index) => {
        return (
            <Column
                key={index}
                day={day}
                hours={hours}
                availableAppointments={availableAppointments}
                bookedAppointments={bookedAppointments}
                booking={booking}
                setBookingHandler={setBookingHandler}
            />
        )
    });

    const prevIndex = targetWeek - 1;
    let prevIsValid = true;
    
    if (targetWeek === 0) {
        prevIsValid = false;
    }

    const nextIndex = targetWeek + 1;
    let nextIsValid = false;

    if (targetWeek < weeks.length - 1) {
        nextIsValid = true;
    }

    let todayIsValid = false;

    if (targetWeek > 0) {
        todayIsValid = true;
    }

    return (
        <React.Fragment>
            <div className="mb-3 d-flex user-select-none align-items-center">
                <div className="d-flex me-2">
                    <Button
                        classes="btn btn-light me-2 d-flex align-items-center px-2"
                        type="button"
                        loading={false}
                        valid={todayIsValid}
                        clickHandler={() => setWeekHandler(0)}
                    >
                        Today
                    </Button>
                    <Button
                        classes="btn btn-light me-2 d-flex align-items-center px-2"
                        type="button"
                        loading={false}
                        valid={prevIsValid}
                        clickHandler={() => setWeekHandler(prevIndex)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    </Button>
                    <Button
                        classes="btn btn-light d-flex align-items-center px-2"
                        type="button"
                        loading={false}
                        valid={nextIsValid}
                        clickHandler={() => setWeekHandler(nextIndex)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </Button>
                </div>
                <div className="fs-5">
                    {`${week[0].format('ddd')} ${week[0].format('MMM')} ${week[0].format('D')} - ${week[6].format('ddd')} ${week[6].format('MMM')} ${week[6].format('D')}`}
                </div>
            </div>
            <div className={`${styles.calendar} d-flex flex-row`}>
                <div className={`${styles.time}`}>
                    <TimeColumn
                        hours={hours}
                    />
                </div>
                <div style={{width: `100%`}}>
                    <div className={`${styles.heading} d-flex flex-row`}>
                        {dayHeadings}
                    </div>
                    <div className="d-flex flex-row align-items-stretch">
                        {dayColumns}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Calendar;