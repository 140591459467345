import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Location from '../components/Home/Location';

const ConfirmAppointment = () => {
    const { bookingId } = useParams();
    const [booking, setBooking] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getBooking = async () => {
            try {
                const { data } = await axios.get(`https://dxaz3vgpvh.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/booking/${bookingId}`);
      
                setBooking(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getBooking();
    }, [bookingId]);

    return (
        <React.Fragment>
            <div className="BAJA-copy mb-6 BAJA-blue-bg">
                <div className="h1 mb-4">
                    Thanks for booking!
                </div>
                <div className="mb-3">
                    Your upcoming appointment:
                </div>
                <div className="fw-bold mb-3">
                    {dayjs(booking.bookingDateTime).format('dddd, MMMM D, YYYY [at] h:mma')}
                </div>
                <div>
                    If you are no longer able to make this appointment, please call us at <a href="tel:6044614343">604-461-4343</a> to cancel.
                </div>
            </div>
            <Location />
        </React.Fragment>
    )
}

export default ConfirmAppointment;