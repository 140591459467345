import React from 'react';
import backgroundImage from '../../baja_home_full.png';
import styles from './Jumbotron.module.css';
// import { Link } from 'react-router-dom';

/*
const Jumbotron = () => {
    return (
        <div className={`${styles.jumbotron}`}>
            <img src={backgroundImage} alt="Baja Autobody Ltd. - Collision Auto Body. Toyota Specialists serving the Tri-Cities since 1975." />
            <div className={`${styles['jumbotron-overlay']} d-flex align-items-center`}>
                <div className="user-select-none">
                    <div className={`${styles['jumbotron-title']}`}>
                        Serving the Tri-Cities since 1975.
                    </div>
                    <div className={`${styles['jumbotron-subtitle']} mb-4`}>
                        Come see us today for a FREE estimate.
                    </div>
                    <Link to="/book-appointment">
                        <div>
                            <button className="btn btn-lg btn-warning">
                                Book an appointment
                            </button>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
*/

const Jumbotron = () => {
    return (
        <div className={`${styles.jumbotron}`}>
            <img src={backgroundImage} alt="Baja Autobody Ltd. - Collision Auto Body. Toyota Specialists serving the Tri-Cities since 1975." />
            <div className={`${styles['jumbotron-overlay']} d-flex align-items-center`}>
                <div className="user-select-none">
                    <div className={`${styles['jumbotron-title']}`}>
                        Serving the Tri-Cities since 1975.
                    </div>
                    <div className={`${styles['jumbotron-subtitle']} mb-4`}>
                        Come see us today for a FREE estimate.
                    </div>
                    <button className="btn btn-lg btn-warning call-button">
                        <a href="tel:6044614343">
                            Call for an appointment
                        </a>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Jumbotron;