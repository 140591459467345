import React from 'react';
import Appointment from './Appointment';

const Agenda = ({ bookings }) => {
    let appointments = null;
    
    appointments = bookings.map((booking, index) => {
        return (
            <Appointment 
                key={index}
                appointment={booking}
            />
        )
    });

    if (bookings.length === 0) {
        appointments = (
            <div className="lead">You have no upcoming appointments.</div>
        )
    }

    return (
        <div className="mb-5">
            {appointments}
        </div>
    )
}

export default Agenda;