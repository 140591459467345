import React from 'react';
import SectionTitle from '../Typography/SectionTitle';
import Input from '../UI/Input';
import TextArea from '../UI/TextArea';

const ClaimInfo = ({ bookingForm, inputChangedHandler }) => {
    return (
        <div className="mb-4">
            <SectionTitle>
                Insurance &amp; Claim Information (Optional)
            </SectionTitle>
            <Input
                type="text"
                id="insuranceCompany"
                label="Insurance Company"
                // helpText={`Please enter your first name.`}
                // helpTextId="customerName-help"
                value={bookingForm.insuranceCompany}
                changed={(event) => inputChangedHandler(event, event.target.id)}
            />
            <Input
                type="text"
                id="claimNumber"
                label="Claim Number"
                // helpText={`Please enter your first name.`}
                // helpTextId="customerName-help"
                value={bookingForm.claimNumber}
                changed={(event) => inputChangedHandler(event, event.target.id)}
            />
            <TextArea
                type="text"
                id="damageDescription"
                label="Damage Description"
                rows="7"
                // helpText={`Please enter your first name.`}
                // helpTextId="customerName-help"
                value={bookingForm.damageDescription}
                changed={(event) => inputChangedHandler(event, event.target.id)}
            />
        </div>
    )
}

export default ClaimInfo;