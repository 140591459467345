import React from 'react';
import styles from './Appointment.module.css';
import dayjs from 'dayjs';

const Appointment = (props) => {
    const appointment = props.appointment;
    const BookingDateTime = appointment.bookingDateTime
    const date = dayjs(BookingDateTime);

    return (
        <div className={`${styles.row} row d-flex align-items-center`}>
            <div className="col-sm-5 d-flex align-items-center">
                <div className={`${styles['column-1']} me-2`}>
                    {date.format('D')}
                </div>
                <div className={`${styles['column-2']} me-2`}>
                    {date.format('MMM, ddd')}
                </div>
                <div className={`${styles['column-3']} me-2`}>
                    {date.format('h:mm')} - {date.add(15, 'm').format('h:mma')}
                </div>
            </div>
            <div className="col-sm-7">
                <div className="fw-bolder">
                    {appointment.firstName} {appointment.lastName} 
                </div>
                <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone me-1" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                    <div>
                        <a href={`tel:${appointment.phone}`}>{appointment.phone}</a>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-1" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                    </svg>
                    <div>
                        <a href={`mailto:${appointment.emailAddress}`}>{appointment.emailAddress}</a>
                    </div>
                </div>
                <div>
                    {appointment.year} {appointment.make} {appointment.model}
                </div>
                <div>
                    {appointment.insuranceCompany}
                </div>
                <div>
                    {appointment.claimNumber}
                </div>
                <div>
                    {appointment.damageDescription}
                </div>
            </div>
        </div>
    )
}

export default Appointment;