import React from 'react';

const PageNotFound = () => {
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="h1 text-center mt-5">
                This page isn't available.
            </div>
            <div className="fs-5 text-muted text-center mt-3">
                The link you followed may be broken, or the page may have been removed.
            </div>
        </div>
    )
}

export default PageNotFound;