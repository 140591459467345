import React from 'react';
import styles from './TimeColumn.module.css';
import TimeCell from './TimeCell';

const TimeColumn = ({ hours }) => {
    const cells = hours.map((cell, index) => {
        return (
            <TimeCell 
                key={index}
                hour={cell.name}
            />
        )
    });

    return (
        <div className={styles.column}>
            {cells}
        </div>
    )
}

export default TimeColumn;