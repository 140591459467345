import React from 'react';
import styles from './Guarantee.module.css';

const Guarantee = () => {
    return (
        <div className={`${styles.guarantee}`}>
            <div className="h1 mb-4">
                Our Guarantee
            </div>
            <div className={`${styles.copy}`}>
                Baja Autobody Ltd. guarantees that all repairs have been completed to acceptable industry standards. This guarantee protects against defective workmanship or materials. Coverage for damage caused by or resulting from corrosion, unreasonable use, maintenance or care of the vehicle is excluded. Providing the vehicle remains owned and registered to the same individual, this guarantee is valid from the date of issue: for the life of the vehicle on body and paint repairs, for such a period of time as specified by the parts supplier.
            </div>
        </div>
    )
}

export default Guarantee;