import React from 'react';
import styles from './TimeCell.module.css';

const TimeCell = (props) => {
    return (
        <div className={`${styles.cell} text-nowrap`}>
            {props.hour}
        </div>
    )
}

export default TimeCell;